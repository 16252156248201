<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" class="toolbar" :model="searchForm">
      <el-form-item label="商户">
        <el-select
          clearable
          size="mini"
          v-model="searchForm.merchantId"
          @change="merchantSelectChange"
        >
          <el-option
            v-for="item in merchantList"
            :key="item.id"
            :label="item.merchantName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="商户单号">
        <el-input v-model="searchForm.orderNo" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="支付单号">
        <el-input v-model="searchForm.tradeNo" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input
          v-model="searchForm.cellphone"
          size="mini"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="IFSC">
        <el-input v-model="searchForm.ifsc" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="用户卡号">
        <el-input v-model="searchForm.cardNo" size="mini" clearable></el-input>
      </el-form-item>
      <el-form-item label="更新日期">
        <el-date-picker
          v-model="searchForm.startTime"
          type="date"
          value-format="yyyy-MM-dd"
          size="mini"
          clearable
        ></el-date-picker
        >--
        <el-date-picker
          v-model="searchForm.endTime"
          type="date"
          value-format="yyyy-MM-dd"
          size="mini"
          clearable
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="渠道">
        <el-select clearable size="mini" v-model="searchForm.channel">
          <el-option
            v-for="item in channelList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="金额" width="120px">
        <el-input
          v-model="searchForm.startAmount"
          size="mini"
          width="60px"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="-">
        <el-input
          v-model="searchForm.endAmount"
          size="mini"
          width="60px"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="search" type="primary" size="mini">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="pullBacks" type="primary" size="mini"
          >拉回</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      border
      height="650"
      style="width: 100%"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        prop="no"
        label="序号"
        width="50"
        type="index"
      ></el-table-column>
      <el-table-column prop="merchantName" label="商户名称"></el-table-column>
      <el-table-column prop="accountName" label="渠道名称"></el-table-column>
      <el-table-column prop="orderNo" label="商户单号"></el-table-column>
      <el-table-column prop="tradeNo" label="支付单号"></el-table-column>
      <el-table-column prop="cardNo" label="用户卡号"></el-table-column>
      <el-table-column prop="ifsc" label="IFSC"></el-table-column>
      <el-table-column prop="amount" label="交易金额">
        <template slot-scope="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="请求时间">
        <template slot-scope="scope">
          <span>{{ dateTransform(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <!--          <el-button type="primary" size="mini" @click="callback(scope.row)">回调</el-button>-->
          <el-button type="primary" size="mini" @click="pullBack(scope.row)"
            >拉回</el-button
          >
          <el-button type="primary" size="mini" @click="reject(scope.row)"
            >失败退回</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
  </section>
</template>

<script>
import api from "../../api/orderManage/lendErrorOrder";
import merchantApi from "@/api/merchantPayConfig/merchantApi";
import DataUtil from "../../utils/config/dataUtil";
import Enum from "../../utils/Enum/Enum";
export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      dataTotal: 0,
      loading: false,
      tableData: [],
      statusList: Enum.orderStatus,
      channelList: [],
      searchForm: {
        merchantId: "",
        transactionId: "",
        orderNo: "",
        tradeNo: "",
        username: "",
        cellphone: "",
        ifsc: "",
        cardNo: "",
        channel: "",
        startAmount: "",
        endAmount: "",
        startTime: this.dateFrom(),
        endTime: this.date4Query(new Date()),
      },
      merchantList: [],
      multipleSelection: [],
    };
  },
  methods: {
    merchantSelectChange(val) {
      this.channelSelect(val);
    },
    merchantSelect() {
      merchantApi.getMerchant({}).then((res) => {
        if (res.code == "0000") {
          this.merchantList = res.data.rows;
        }
      });
    },
    channelSelect(val) {
      merchantApi
        .getChannel({
          merchantId: val,
        })
        .then((res) => {
          if (res.code == "0000") {
            this.channelList = res.data.split(",");
          }
        });
    },
    dateTransform(date) {
      return DataUtil.dateTransform(date);
    },
    date4Query(date) {
      return DataUtil.dateTransformYMD(date);
    },
    dateFrom() {
      let dt = new Date();
      dt.setDate(dt.getDate() - 3);
      return DataUtil.dateTransformYMD(dt);
    },
    search() {
      this.pageIndex = 1;
      this.bindGrid();
    },
    callback(row) {
      this.$confirm("确认回调该订单么？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let _data = {};
          let content = {};
          content.id = row.id;
          _data.content = content;
          api.callbackOrder(_data).then((res) => {
            this.logining = false;
            if (res.code == "0000") {
              this.$message({
                message: "回调成功",
                type: "success",
              });
              this.bindGrid();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        ["catch"](() => {
          this.$message({
            type: "info",
            message: "已取消回调",
          });
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    pullBack(row) {
      this.$confirm("确定拉回？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let _data = {};
          let content = {};
          content.ids = row.id;
          _data.content = content;
          api.pullBackOrder(_data).then((res) => {
            this.logining = false;
            if (res.code == "0000") {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.bindGrid();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        ["catch"](() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    pullBacks() {
      if (this.multipleSelection.length > 0) {
        let ids = null;
        let idList = [];
        this.multipleSelection.forEach((item) => {
          idList.push(item.id);
        });
        ids = idList.join(",");

        const h = this.$createElement;
        this.$msgbox({
          title: "确认提示",
          message: h("p", null, [
            h("span", null, " 确认要拉回"),
            h(
              "i",
              { style: "color: red;font-weight: bold;" },
              this.multipleSelection.length
            ),
            h("span", null, "单吗？"),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = "执行中...";
              console.log(ids);

              let _data = {};
              let content = {};
              content.ids = ids;
              _data.content = content;
              api.pullBackOrder(_data).then((res) => {
                this.logining = false;
                if (res.code == "0000") {
                  this.$message({
                    message: "操作成功",
                    type: "success",
                  });
                  instance.confirmButtonLoading = false;
                  this.bindGrid();
                  done();
                } else {
                  this.$message.error(res.message);
                  instance.confirmButtonLoading = false;
                }
              });
            } else {
              instance.confirmButtonLoading = false;
              done();
            }
          },
        });
      } else {
        this.$message.error("请选择要拉回的订单");
        return;
      }
    },
    reject(row) {
      this.$confirm("确认失败处理？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let _data = {};
          let content = {};
          content.id = row.id;
          _data.content = content;
          api.rejectOrder(_data).then((res) => {
            this.logining = false;
            if (res.code == "0000") {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.bindGrid();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        ["catch"](() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    bindGrid() {
      this.loading = true;
      if (this.searchForm.startTime == null) {
        this.searchForm.startTime = "";
      }
      if (this.searchForm.endTime == null) {
        this.searchForm.endTime = "";
      }
      let _data = {};
      let content = Object.assign({}, this.searchForm);
      content.pageNo = this.pageIndex;
      content.pageSize = this.pageSize;
      content.startAmount =
        this.searchForm.startAmount == ""
          ? ""
          : this.searchForm.startAmount * 100;
      content.endAmount =
        this.searchForm.endAmount == "" ? "" : this.searchForm.endAmount * 100;
      _data.channel = this.searchForm.channel;
      _data.startAmount =
        this.searchForm.startAmount == ""
          ? ""
          : this.searchForm.startAmount * 100;
      _data.endAmount =
        this.searchForm.endAmount == "" ? "" : this.searchForm.endAmount * 100;
      _data.content = content;
      api.getLengErrorOrderList(_data).then((res) => {
        this.logining = false;
        if (res.code == "0000") {
          this.loading = false;
          this.tableData = res.data.rows;
          this.dataTotal = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 分页
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.bindGrid();
    },
    pageIndexChange(val) {
      this.pageIndex = val;
      this.bindGrid();
    },
  },
  mounted: function () {
    this.bindGrid();
    this.merchantSelect();
    this.channelSelect("");
  },
  components: {},
};
</script>

<style lang="scss">
@import "../../assets/css/base";
.el-form-item {
  margin-bottom: 10px;
}
</style>
